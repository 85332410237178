// const productIdSample = ["", "mega", "power", "max4d", "max3d", "max3d", "keno"];

/* eslint eqeqeq: "off" */

const productData = {
    "mega": {
        "ve_don": {
            "id": 1,
            "price": 10,
            "name": "Vé đơn"
        },
        "bao_5": {
            "id": 2,
            "price": 400,
            "name": "Bao 5"
        },
        "bao_7": {
            "id": 3,
            "price": 70,
            "name": "Bao 7"
        },
        "bao_8": {
            "id": 4,
            "price": 280,
            "name": "Bao 8"
        },
        "bao_9": {
            "id": 5,
            "price": 840,
            "name": "Bao 9"
        },
        "bao_10": {
            "id": 6,
            "price": 2100,
            "name": "Bao 10"
        },
        "bao_11": {
            "id": 7,
            "price": 4620,
            "name": "Bao 11"
        },
        "bao_12": {
            "id": 8,
            "price": 9240,
            "name": "Bao 12"
        },
        "bao_13": {
            "id": 9,
            "price": 17160,
            "name": "Bao 13"
        },
        "bao_14": {
            "id": 10,
            "price": 30030,
            "name": "Bao 14"
        },
        "bao_15": {
            "id": 11,
            "price": 50050,
            "name": "Bao 15"
        },
        "bao_18": {
            "id": 12,
            "price": 185564,
            "name": "Bao 18"
        }
    },
    "power": {
        "ve_don": {
            "id": 21,
            "price": 10,
            "name": "Vé đơn"
        },
        "bao_5": {
            "id": 22,
            "price": 400,
            "name": "Bao 5"
        },
        "bao_7": {
            "id": 23,
            "price": 70,
            "name": "Bao 7"
        },
        "bao_8": {
            "id": 24,
            "price": 280,
            "name": "Bao 8"
        },
        "bao_9": {
            "id": 25,
            "price": 840,
            "name": "Bao 9"
        },
        "bao_10": {
            "id": 26,
            "price": 2100,
            "name": "Bao 10"
        },
        "bao_11": {
            "id": 27,
            "price": 4620,
            "name": "Bao 11"
        },
        "bao_12": {
            "id": 28,
            "price": 9240,
            "name": "Bao 12"
        },
        "bao_13": {
            "id": 29,
            "price": 17160,
            "name": "Bao 13"
        },
        "bao_14": {
            "id": 30,
            "price": 30030,
            "name": "Bao 14"
        },
        "bao_15": {
            "id": 31,
            "price": 50050,
            "name": "Bao 15"
        },
        "bao_18": {
            "id": 32,
            "price": 185564,
            "name": "Bao 18"
        }
    },
    "max4d": {
        "ve_don": {
            "id": 41,
            "price": 0,
            "name": "Vé đơn"
        },
        "ve_bao": {
            "id": 42,
            "price": 0,
            "name": "Vé bao"
        },
        "cuon_1": {
            "id": 43,
            "price": 0,
            "name": "Cuộn 1"
        },
        "cuon_4": {
            "id": 44,
            "price": 0,
            "name": "Cuộn 4"
        },
        "to_hop": {
            "id": 45,
            "price": 0,
            "name": "Tổ hợp"
        }
    },
    "max3d": {
        "max3d": {
            "id": 51,
            "name": "Max 3D",
            "price": 0
        },
        "max3d_plus": {
            "id": 61,
            "name": "Max 3D+",
            "price": 0
        }
    },
    "keno": {

    }
};


const productDataset = [
    {
        "id": 1,
        "name": "Mega 6/45",
        "slug": "mega",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 1,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 45,
                "price": 10
            },
            {
                "id": 2,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 45,
                "price": 400
            },
            {
                "id": 3,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 45,
                "price": 70
            },
            {
                "id": 4,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 45,
                "price": 280
            },
            {
                "id": 5,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 45,
                "price": 840
            },
            {
                "id": 6,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 45,
                "price": 2100
            },
            {
                "id": 7,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 45,
                "price": 4620
            },
            {
                "id": 8,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 45,
                "price": 9240
            },
            {
                "id": 9,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 45,
                "price": 17160
            },
            {
                "id": 10,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 45,
                "price": 30030
            },
            {
                "id": 11,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 45,
                "price": 50050
            },
            {
                "id": 12,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 45,
                "price": 185564
            }
        ]
    },
    {
        "id": 2,
        "name": "Power 6/55",
        "slug": "power",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 21,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 55,
                "price": 10
            },
            {
                "id": 22,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 55,
                "price": 500
            },
            {
                "id": 23,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 55,
                "price": 70
            },
            {
                "id": 24,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 55,
                "price": 280
            },
            {
                "id": 25,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 55,
                "price": 840
            },
            {
                "id": 26,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 55,
                "price": 2100
            },
            {
                "id": 27,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 55,
                "price": 4620
            },
            {
                "id": 28,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 55,
                "price": 9240
            },
            {
                "id": 29,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 55,
                "price": 17160
            },
            {
                "id": 30,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 55,
                "price": 30030
            },
            {
                "id": 31,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 55,
                "price": 50050
            },
            {
                "id": 32,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 55,
                "price": 185564
            }
        ]
    },
    {
        "id": 3,
        "name": "MAX 4D",
        "slug": "max4d",
        "priceArray": [
            10,
            20,
            50,
            100,
            200,
            500,
            1000
        ],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 41,
                "name": "Vé thường",
                "slug": "ve_thuong",
                "select": 1,
                "startNumber": 0,
                "endNumber": 9999,
                "price": 0
            },
            {
                "id": 42,
                "name": "Vé bao",
                "slug": "ve_bao",
                "select": 1,
                "startNumber": 0,
                "endNumber": 9999,
                "price": 0
            },
            {
                "id": 43,
                "name": "Cuộn 1",
                "slug": "cuon_1",
                "select": 1,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            },
            {
                "id": 44,
                "name": "Cuộn 4",
                "slug": "cuon_4",
                "select": 1,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            },
            {
                "id": 45,
                "name": "Tổ hợp",
                "slug": "to_hop",
                "select": 1,
                "startNumber": 0,
                "endNumber": 9999,
                "price": 0
            }
        ]
    },
    {
        "id": 4,
        "name": "MAX 3D",
        "slug": "max3d",
        "priceArray": [
            10,
            20,
            50,
            100,
            200,
            500,
            1000
        ],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 51,
                "name": "Max 3D",
                "slug": "max3d",
                "select": 1,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            }
        ]
    },
    {
        "id": 5,
        "name": "MAX 3D+",
        "slug": "max3d_plus",
        "priceArray": [
            10,
            20,
            50,
            100,
            200
        ],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 61,
                "name": "Max 3D+",
                "slug": "max3d_plus",
                "select": 2,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            }
        ]
    },
    {
        "id": 6,
        "name": "Keno",
        "slug": "keno",
        "priceArray": [
            10,
            20,
            30,
            50,
            100,
            200,
            500
        ],
        productOptionArray: [
            {
                "id": 71,
                "name": "Bậc 1",
                "select": 1,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 72,
                "name": "Bậc 2",
                "select": 2,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 73,
                "name": "Bậc 3",
                "select": 3,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 74,
                "name": "Bậc 4",
                "select": 4,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 75,
                "name": "Bậc 5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 76,
                "name": "Bậc 6",
                "select": 6,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 77,
                "name": "Bậc 7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 78,
                "name": "Bậc 8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 79,
                "name": "Bậc 9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 80,
                "name": "Bậc 10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 81,
                "name": "Bậc 10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 82,
                "name": "Chẵn/Lẻ - Lớn/Nhỏ",
                "slug": "keno",
                "select": 1,
                "startNumber": 1,
                "endNumber": 4,
                "price": 0
            }
        ],
        productOptionKenoTypeArray: [
            {
                "id": 2,
                "name": "Bao 2",
                "select": 2,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 3,
                "name": "Bao 3",
                "select": 3,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 4,
                "name": "Bao 4",
                "select": 4,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 5,
                "name": "Bao 5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 6,
                "name": "Bao 6",
                "select": 6,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 7,
                "name": "Bao 7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 8,
                "name": "Bao 8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 9,
                "name": "Bao 9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            {
                "id": 10,
                "name": "Bao 10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 80,
                "price": 0
            },
            // {
            //     "id": 11,
            //     "name": "Bao 11",
            //     "select": 11,
            //     "startNumber": 1,
            //     "endNumber": 80,
            //     "price": 0
            // },
            // {
            //     "id": 12,
            //     "name": "Bao 12",
            //     "select": 12,
            //     "startNumber": 1,
            //     "endNumber": 80,
            //     "price": 0
            // },
            // {
            //     "id": 13,
            //     "name": "Bao 13",
            //     "select": 13,
            //     "startNumber": 1,
            //     "endNumber": 80,
            //     "price": 0
            // },
            // {
            //     "id": 14,
            //     "name": "Bao 14",
            //     "select": 14,
            //     "startNumber": 1,
            //     "endNumber": 80,
            //     "price": 0
            // },
            // {
            //     "id": 15,
            //     "name": "Bao 15",
            //     "select": 15,
            //     "startNumber": 1,
            //     "endNumber": 80,
            //     "price": 0
            // },
        ],
    },
    {
        "id": 7,
        "name": "Lô tô 2 số, 3 số, 5 số",
        "slug": "235",
        "priceArray": [
            
            20,
            50,
            100,
            200,
            500,
            5000
        ],
        "numberTicket": 5,
        "productOptionArray": [
            {
            "id": 83,
            "name": "Lô tô 2 số",
            "select": 2,
            "startNumber": 0,
            "endNumber": 9,
            "price": 0
            },
            {
            "id": 84,
            "name": "Lô tô 3 số",
            "select": 3,
            "startNumber": 0,
            "endNumber": 9,
            "price": 0
            },
            {
            "id": 85,
            "name": "Lô tô 5 số",
            "select": 5,
            "startNumber": 0,
            "endNumber": 9,
            "price": 0
            }
        ],
    },
    {
        "id": 8,
        "name": "Lô tô 2, 3, 4 cặp số",
        "slug": "234",
        "priceArray": [
            20,
            50,
            100,
            200,
            500,
            5000
        ],
        "numberTicket": 5,
        "productOptionArray": [
            {
            "id": 86,
            "name": "Lô tô 2 cặp số",
            "select": 2,
            "startNumber": 0,
            "endNumber": 99,
            "price": 0
            },
            {
            "id": 87,
            "name": "Lô tô 3 cặp số",
            "select": 3,
            "startNumber": 0,
            "endNumber": 99,
            "price": 0
            },
            {
            "id": 88,
            "name": "Lô tô 4 cặp số",
            "select": 4,
            "startNumber": 0,
            "endNumber": 99,
            "price": 0
            }
        ],
    },
    {
        "id": 9,
        "name": "6x36",
        "slug": "6x36",
        "priceArray": [
            5
        ],
        "numberTicket": 5,
        "productOptionArray": [
            {
            "id": 89,
            "name": "Cơ bản",
            "select": 6,
            "startNumber": 1,
            "endNumber": 36,
            "price": 5
            }           
        ],
    },
    {
        "id": 10,
        "name": "Thần tài",
        "slug": "than-tai",
        "priceArray": [
            20,
            50,
            100,
            200,
            500,
            5000
        ],
        "numberTicket": 5,
        "productOptionArray": [
            {
            "id": 90,
            "name": "Thần tài",
            "select": 4,
            "startNumber": 0,
            "endNumber": 9,
            "price": 0
            }           
        ],
    },
    {
        "id": 11,
        "name": "1 2 3",
        "slug": "123",
        "priceArray": [
            20,
            50,
            100,
            200,
            500,
            5000
        ],
        "numberTicket": 5,
        "productOptionArray": [
            {
            "id": 91,
            "name": "Điện toán 123",
            "select": 3,
            "startNumber": 0,
            "endNumber": 9,
            "price": 0
            }           
        ],
    },
    {
        "id": 12,
        "name": "Max 3D Pro",
        "slug": "max3d_pro",
        "priceArray": [
            10,
            20,
            50,
            100,
            200
        ],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 92,
                "name": "Vé thường",
                "slug": "max3d_pro",
                "select": 2,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            },
            {
                "id": 95,
                "name": "Bao bộ số",
                "slug": "max3d_pro",
                "select": 2,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            },
            {
                "id": 96,
                "name": "Bao nhiều số",
                "slug": "max3d_pro",
                "select": 2,
                "startNumber": 0,
                "endNumber": 999,
                "price": 0
            }
        ]
    },
];

function getKeyNameById(productId, productOptionId) {

    if ((typeof productId) === "number") {
        let product = productDataset.find(dt => dt.id == productId);
        if (product === undefined)
            return null;
        let productOption = product.find(dt => dt.id == productOptionId);
        if (productOption === undefined)
            return null;
        return productOption.slug;
    } else {
        let product = productDataset.find(dt => dt.id == productId);
        if (product === undefined)
            return null;
        let productOption = product.find(dt => dt.id == productOptionId);
        if (productOption === undefined)
            return null;
        return productOption.slug;
    }
}

function getProductOptionIdName(productId, productOptionId) {
    let product = productDataset.find(dt => dt.id === productId);
    return product.productOptionArray.find(dt => dt.id === productOptionId).name;
}

function getProductName(productId) {
    return productDataset.find(dt => dt.id === productId).name;
}

function getGain(productId, productOptionId, number) {
    switch (productId) {
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
            return 1;
        case 3:
            switch (productOptionId) {
                case 43:
                case 44:
                    return 10;
                case 42:
                    let num = number.split('');
                    let count = [0, 0, 0, 0];
                    for (let i = 0; i < num.length; i++) {
                        count[i] = num.filter(x => x === num[i]).length;
                    }

                    if (count.indexOf(3) !== -1) {
                        return 4;
                    }

                    if (count.indexOf(2) !== -1 && count.reduce((a, b) => a + b, 0) === 8) {
                        return 6;
                    }

                    if (count.indexOf(2) !== -1 && count.reduce((a, b) => a + b, 0) !== 8) {
                        return 12;
                    }

                    if (count.indexOf(1) !== -1) {
                        return 24;
                    }
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return 1;
}

function getPrice(value, termArray, productId, productOptionId) {
    let totalPrice = 0;
    value.forEach(dt => {
        if (dt.number.length > 0) {
            totalPrice = totalPrice + dt.price * getGain(productId, productOptionId, dt.number[0]);
        }
    });
    return totalPrice * termArray.length;
}

module.exports = {
    productData: productData,
    productDataset: productDataset,
    getKeyNameById: getKeyNameById,
    getProductOptionIdName: getProductOptionIdName,
    getProductName: getProductName,
    getPrice: getPrice
};
